import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export interface UserData {
  data: {
      id: string;
      type: string;
      attributes: {
          activated: boolean;
          country_code: string | null;
          email: string;
          first_name: string | null;
          full_phone_number: string;
          last_name: string | null;
          full_name: string;
          gender: string | null;
          phone_number: string | null;
          type: string;
          created_at: string;
          updated_at: string;
          device_id: string | null;
          unique_auth_id: string;
          user_interests: {
              id: number;
              interest_id: number;
              account_id: number;
              created_at: string;
              updated_at: string;
          }[];
          interest_name: {
              id: number;
              name: string;
              created_by_admin: boolean;
              created_at: string;
              updated_at: string;
              interest_category: string;
              status: string;
          }[];
          photo: string;
          cover_picture: string;
          profession: string | null;
          city: string | null;
          state: string | null;
          country: string;
          partnership_practitioner: boolean | null;
          accredited_partnership_broker: boolean;
          organizational_affilation: string | null;
          preferred_language: string;
          skill_name: {
              id: number;
              name: string;
              created_by_admin: boolean;
              created_at: string;
              updated_at: string;
              interest_category: string;
              status: string;
          }[];
          experience: string | null;
          last_login_at: string | null;
          connections: any[]; // Specify further if connections have a structure
          about_yourself: string | null;
          time_zone: string | null;
          working_language: string | null;
          number_visible: boolean;
      };
  }[];
  meta: {
        total_count: number,
        current_page: number,
        per_page: number,
        total_pages: number
  }
}

interface UserInterest {
  id: number;
  interest_id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
}

interface InterestName {
  id: number;
  name: string;
  created_by_admin: boolean;
  created_at: string;
  updated_at: string;
  interest_category: string;
  status: string;
}

interface SkillName {
  id: number;
  name: string;
  created_by_admin: boolean;
  created_at: string;
  updated_at: string;
  interest_category: string;
  status: string;
}

interface Attributes {
  activated: boolean;
  country_code: string | null;
  email: string;
  first_name: string | null;
  full_phone_number: string;
  last_name: string | null;
  full_name: string;
  gender: string | null;
  phone_number: string | null;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  user_interests: UserInterest[];
  interest_name: InterestName[];
  photo: string;
  cover_picture: string | null;
  profession: string | null;
  city: string | null;
  state: string | null;
  country: string;
  partnership_practitioner: string | null;
  accredited_partnership_broker: boolean;
  organizational_affilation: string | null;
  preferred_language: string;
  skill_name: SkillName[];
  experience: string | null;
  last_login_at: string | null;
  connections: any[];
  about_yourself: string | null;
  time_zone: string | null;
  working_language: string | null;
  number_visible: boolean;
}

interface MemberDetails {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface MemberProp {
  path: string;
  name: string;
  location: string;
  mission: string;
  affiliation: string[];
  skills: string[];
}

export interface MemberDirectoryListProps {
  membersData: any;
  navigation: any;
  getMemberDetail: any;
  memberDetails: any;
}

export interface MemberDirectoryListState {
  showAll: { [index: number]: boolean };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  membersData?: any;
  getMemberDetail?: any;
  memberDetails?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedLanguage: string;
  selectedCountry: string;
  selectedState: string;
  selectedCity: string;
  selectedRegion: string;
  clearFilters: boolean;
  membersData: any;
  memberId: string;
  memberDetails: MemberDetails,
  token: string;
  searchFeildOpen:boolean;
  searchData: string;
  showAll:any;
  pageNumber: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MemberDirectoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  dropdownRef: any;
  // Customizable Area Start
  getMemberInfo: string = "";
  getMemberDetails: string = "";
  getSearchDataApiCallId:string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedLanguage: "",
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      selectedRegion: "",
      membersData: {
        data: [],
        meta: {
          total_count: 0,
          current_page: 0,
          per_page: 0,
          total_pages: 0
        }
      },
      searchFeildOpen: false,
      searchData:'',
      clearFilters: false,
      memberId: "",
      token: String(localStorage.getItem("authToken")),
      showAll:{},
      memberDetails: {
        id: "",
        type: "",
        attributes: {
          activated: false,
          country_code: null,
          email: "",
          first_name: null,
          full_phone_number: "",
          last_name: null,
          full_name: "",
          gender: null,
          phone_number: null,
          type: "",
          created_at: "",
          updated_at: "",
          device_id: null,
          unique_auth_id: "",
          user_interests: [],
          interest_name: [],
          photo: "",
          cover_picture: null,
          profession: null,
          city: null,
          state: null,
          country: "",
          partnership_practitioner: null,
          accredited_partnership_broker: false,
          organizational_affilation: null,
          preferred_language: "",
          skill_name: [],
          experience: null,
          last_login_at: null,
          connections: [],
          about_yourself: null,
          time_zone: null,
          working_language: null,
          number_visible: false,
        },
      },
      pageNumber: 1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      

      if (apiRequestCallId != null) {
         if(apiRequestCallId === this.getMemberInfo){
          this.setState({
            membersData: responseJson
          }); 
        }
      }
      this.apiCallgetMemberDetails(apiRequestCallId, responseJson)
      this.handleSearchApiCall(apiRequestCallId, responseJson)
  }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  apiCallgetMemberDetails = (apiRequestCallId:any, responseJson:any) => {
    if (apiRequestCallId === this.getMemberDetails) {
      if (responseJson != null) {
        if (responseJson) {
          let postsData = responseJson;
          this.setState({
            memberDetails: postsData
          });
          setStorageData('memberDetailsDatas', JSON.stringify(postsData))
        }
      }
      this.props.navigation.navigate('MemberDetails')
    }
  }

  handleSearchApiCall = (apiResuestCallId:any, responseJson:any)=>{
    if(apiResuestCallId ===this.getSearchDataApiCallId){
      if(!responseJson?.errors){
        this.setState({membersData:responseJson})
      }else{
        this.setState({membersData:{data:[]}})
      }
    }
  }

  async componentDidMount(){
    const memberId = await getStorageData("memberId")
    this.setState({
      memberId: memberId
    })
    this.getMemberInfoApiCall()
  }
  setLanguage = (state: string) => {
    this.setState({ selectedLanguage: state },()=>{
      this.getMemberInfoApiCall()
    })
  }

  setCountry = (state: string) => {
    this.setState({ selectedCountry: state },()=>{
      this.getMemberInfoApiCall()
    })
  }

  openSearchTab =()=>{
    this.setState({searchFeildOpen: !this.state.searchFeildOpen})
  }

  setGivenState = (state: string) => {
    this.setState({ selectedState: state },()=>{
      this.getMemberInfoApiCall()
    })
  }

  setCity = (state: string) => {
    this.setState({ selectedCity: state },()=>{
      this.getMemberInfoApiCall()
    })
  }

  setRegion= (state: string) => {
    this.setState({ selectedRegion: state },()=>{
      this.getMemberInfoApiCall()
    })
  }

  clearAllFilters = ()=>{
    this.setState({selectedCity: '', 
      selectedLanguage: '',
      selectedCountry: '',
      selectedRegion: '',
      selectedState: '',
    },()=>{
      this.getMemberInfoApiCall()
    })
    this.setState(prevState =>({
      clearFilters: !prevState.clearFilters
    }))
  }

  handlePagination = (event:any, value: number) => {
    this.setState({
      pageNumber : value
    },()=>{
      this.getMemberInfoApiCall()
    })
  }  

  getMemberInfoApiCall = () => {

    const header = {
        "Content-Type": "application/json",
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMemberInfo = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account/accounts?page=${this.state.pageNumber}&search_params[country]=${this.state.selectedCountry}&search_params[city]=${this.state.selectedCity}&search_params[working_language]=${this.state.selectedLanguage}&search_params[region]=${this.state.selectedRegion}&search_params[state]=${this.state.selectedState}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.getMemberInfo;
};

  handleSearchData = (event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({searchData: event.target.value})
    if(event.target.value!==''){
      this.getMembersSearchApiCall(event.target.value)
    }else {
      this.getMemberInfoApiCall()
    }
  }

  getMembersSearchApiCall = (searchData:string) => {
    const header = {
      "Content-Type": "application/json",
      'token':localStorage.getItem("authToken")
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account/accounts/search?data[attributes][query]=${searchData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


navigateToMemberDetail = (memberId: string) => {
  setStorageData('dataId', memberId)
  const memberdetails: Message = new Message(getName(MessageEnum.NavigationMessage));
        memberdetails.addData(getName(MessageEnum.NavigationTargetMessage), "MemberDetails");
        memberdetails.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(memberdetails);
};

toggleShowAll = (index: number) => {
  this.setState((prevState) => ({
      showAll: {
          ...prevState.showAll,
          [index]: !prevState.showAll[index]
      }
  }));
};
  // Customizable Area End
}
